// 
// dropdown.scss
//

.dropdown-menu {
    box-shadow: $box-shadow;
    animation-name: DropDownSlide;
    animation-duration: .3s;
    animation-fill-mode: both;
    margin: 0;
    font-size: $font-size-base;
    position: absolute;
    z-index: 1000;

    &.show {
        top: 100%!important;
    }
}

.dropdown-header{
    color: $gray-800;
}

.dropdown-divider {
    border-color: lighten($gray-100, 4%);
}

.dropdown-menu-right {
    right: 0!important;
    left: auto!important;
}

.dropdown-menu[x-placement^=right],.dropdown-menu[x-placement^=top],.dropdown-menu[x-placement^=left]{
    top: auto !important;
    animation: none !important;
}

@keyframes DropDownSlide {
    100% {
        -webkit-transform: translateY(0);
        transform: translateY(0)
    }
    0% {
        -webkit-transform: translateY(40px);
        transform: translateY(40px)
    }
}


// Dropdown Large (Custom)
.dropdown-lg {
    width: $dropdown-lg-width;
}

@include media-breakpoint-down(sm) {
    .dropdown-lg {
        width: 200px !important;
    }
}

.custom-lang-select {
    min-width: 150px;
    margin-top: 15px;
}

.custom-project-select {
    min-width: 200px;
    margin-top: 15px;
    margin-right: 15px;
}

.custom-project-label {
    display: inline-block;
    /* min-width: 150px; */
    margin-top: 20px;
    margin-right: 15px;
}

.custom-project-label span {
    vertical-align: text-top;
}