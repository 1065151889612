// 
// tables.scss
//


//Table centered (Custom)
.table-centered {
    td,th {
        vertical-align: middle !important;
    }
}


// Custom table components (Custom)
.table {
    th {
        font-weight: $font-weight-semibold;
    }
    .table-user {
        img {
            height: 30px;
            width: 30px;
        }
    }
}

.action-icon {
    color: $gray-600;
    font-size: 1.2rem;
    display: inline-block;
    padding: 0 3px;

    &:hover {
        color: $gray-700;
    }
}

#dtCallLog_filter {
    float: right;
}

#dtCallLog_paginate {
    float: right;
}

#dtTasksLog_filter {
    float: right;
}

#dtTasksLog_paginate {
    float: right;
}

//Custom scrollbar for datatable
.scrollbar::-webkit-scrollbar
{
    width: 6px!important;
    background-color: #000000!important;
}
 
.scrollbar::-webkit-scrollbar-thumb
{
    border-radius: 10px!important;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3)!important;
    background-color: #FFFFFF!important;
}